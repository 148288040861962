<template>
  <div v-if="show" class="lang-switcher">
    <v-menu
      offset-y
      content-class="elevation-1"
    >
      <template v-slot:activator="{ on, attrs }">
        <btn
          v-bind="attrs"
          v-on="on"
          :width="width"
          :height="height"
          :color="color"
          text tile
        >
          {{ computedLocale }}
          <v-icon small>
            mdi-menu-down
          </v-icon>
        </btn>
      </template>

      <list
        :items="computedItems"
      />

    </v-menu>
  </div>
</template>

<script>
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/utils/constants';

export default {
  props: {
    width: [ String, Number ],
    height: [ String, Number ],
    color: String,
    locale: {
      type: String,
      default: DEFAULT_LANGUAGE
    },
    languages: {
      type: [ String, Array ],
      default: () => SUPPORTED_LANGUAGES
    }
  },
  computed: {
    show() {
      return SUPPORTED_LANGUAGES.length > 1;
    },
    computedLocale() {
      return this.locale.toUpperCase();
    },
    computedLanguages() {
      if ( Array.isArray( this.languages )) return this.languages;
      return this.languages.split(/\s*,\s*/);
    },
    computedItems() {
      return this.computedLanguages
        .filter( locale => locale !== this.locale )
        .map( locale => ({
          text: locale.toUpperCase(),
          to: this.$lp( this.$route, locale )
        }));
    }
  }
}
</script>
