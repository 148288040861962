<template>
  <v-progress-linear
    color="secondary"
    :active="loading"
    :value="progress"
    :indeterminate="!progress"
    absolute top
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Loading',
  computed: mapState( 'app', [ 'loading', 'progress' ])
}
</script>
