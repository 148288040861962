<template>
  <v-app id="app" :class="classes">
    <v-app-bar
      class="primary--text"
      color="white"
      elevation="0"
      height="56"
      style="z-index:8"
      app
    >

      <template v-for="(item,i) in breadcrumbs">
        <btn
          :key="`btn-${i}`"
          class="px-2"
          v-bind="item.props"
          text
        >
          {{ item.text }}
        </btn>
        <span :key="`div-${i}`" class="mr-2">/</span>
      </template>

      <v-icon
        v-if="props.icon"
        class="mr-2"
        color="primary"
      >
        {{ props.icon }}
      </v-icon>

      <h3
        v-if="props.title"
        class="text-h5"
      >
        {{ props.title }}
      </h3>

      <v-spacer/>

      <lang-switcher
        :locale="$i18n.locale"
        color="white"
        height="56"
      />

      <btn
        icon="mdi-menu"
        @click="onClickMenu"
      />

      <loading/>

    </v-app-bar>
    <v-navigation-drawer
      id="navigation"
      v-model="drawer"
      :width="drawerWidth"
      :mobile-breakpoint="768"
      style="z-index:9"
      color="primary"
      app floating
    >

      <logo
        class="shrink pa-1"
        bgcolor="black"
        color="white"
        :min="drawerMin"
        :height="drawerMin ? 56 : 113"
        :show-version="!drawerMin"
      />

      <v-divider/>

      <div class="grow relative">
        <list
          id="main-menu"
          class="absolute layer overflow-y-auto custom-scrollbar"
          active-class="white elevation-2"
          color="black"
          :items="menu"
          selectable
          padless
        />
      </div>

      <div class="pa-2">
        <btn @click="logout" class="px-0" color="black" small dark block>
          <v-icon size="16" :class="{ 'mr-2': !drawerMin }">
            mdi-power
          </v-icon>
          <span v-if="!drawerMin">
            {{ $t('btn.logout') }}
          </span>
        </btn>
      </div>

    </v-navigation-drawer>

    <v-main>

      <v-divider/>

      <alerts absolute/>

      <router-view/>

    </v-main>
  </v-app>
</template>

<script>
import Loading from '@/components/Loading';
import Alerts from '@/components/Alerts';
import LangSwitcher from '@/components/LangSwitcher';
import { wrapInArray } from 'vuetify/lib/util/helpers';
import { mapState, mapActions } from 'vuex';
import { ICONS } from '@/utils/constants';

export default {
  components: { Loading, Alerts, LangSwitcher },
  props: ['title','icon'],
  data: () => ({
    lazyDrawerWidth: null,
    drawerMaxWidth: 240,
    drawerMinWidth: 50
  }),
  computed: {
    ...mapState( 'app', [ 'props', 'isMobile' ]),
    ...mapState( 'auth', [ 'user', 'isAdmin', 'isSuperAdmin' ]),
    classes() {
      return {
        '--drawer': this.drawer,
        '--drawer-min': this.drawerMin,
        '--mobile': this.isMobile
      };
    },
    canAdministrate() {
      return this.isAdmin || this.isSuperAdmin;
    },
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set( drawer ) {
        this.$store.commit('app/set', { drawer });
      }
    },
    drawerMin: {
      get() {
        return this.$store.state.app.drawerMin;
      },
      set( drawerMin ) {
        this.$store.commit('app/set', { drawerMin });
      }
    },
    drawerWidth() {
      return this.isMobile
        ? this.drawerMaxWidth
        : this.drawerMin
          ? this.drawerMinWidth
          : this.drawerMaxWidth;
    },
    breadcrumbs() {
      return wrapInArray( this.props.breadcrumbs ).filter( Boolean ).map( item => {
        if ( typeof item !== 'object' ) item = { text: item };
        let { text, ...props } = item;
        return { text, props };
      });
    },
    menu() {
      return [
        { id: 'profile' },
        this.canAdministrate && { id: 'users' },
        this.canAdministrate && { id: 'appUsers', to: this.$lp('/app/users') },
        { id: 'offerMatch', to: this.$lp('/booking') },
        { id: 'customer' },
        { id: 'offer' },
        { id: 'area' },
        { id: 'tag' },
        { id: 'category' },
        { id: 'categoryFilter', to: this.$lp('/category-filter') },
        { id: 'categoryFilterItem', to: this.$lp('/category-filter-item') }
      ]
      .filter( Boolean )
      .map( item => ({
        text: !item.text && this.$t(`menu.${item.id}`),
        to: !item.to && this.$lp(`/${item.id}`),
        icon: ICONS[item.id],
        ...item
      }));
    }
  },
  watch: {
    drawerWidth( width ) {
      clearInterval( this.interval );
      this.interval = setTimeout(() => {
        this.lazyDrawerWidth = width;
      }, 300 );
    }
  },
  methods: {
    ...mapActions( 'auth', [ 'logout' ]),
    onClickMenu() {
      if ( this.isMobile ) {
        this.drawer = !this.drawer;
      } else {
        this.drawerMin = !this.drawerMin;
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  background-color: var(--v-background-base);
  overflow: hidden;
}
#navigation > .v-navigation-drawer__content {

  display: flex;
  flex-direction: column;

  .logotype {
    transition: height 0.2s ease;
    .logotype--version {
      bottom: 21.5%;
      left: 65%;
    }
  }
}
#main-menu {
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  .theme--light.v-icon {
    color: black;
  }
  .theme--light.v-list-item--active::before {
    display: none;
  }
}

</style>
